export const grids = {}

export const buttons = {
  primary: {
    fontSize: 2,
    fontWeight: 'bold',
    color: 'background',
    bg: 'primary',
    borderRadius: 'default',
  },
  close: {},
  icon: {},
  menu: {},
  outline: {
    variant: 'buttons.primary',
    color: 'primary',
    bg: 'transparent',
    boxShadow: 'inset 0 0 2px',
  },
  secondary: {
    variant: 'buttons.primary',
    color: 'background',
    bg: 'secondary',
  },
  ghost: {
    bg: 'transparent',
    color: 'inherit',
    p: 0,
  },
}

export const text = {
  caps: {
    textTransform: 'uppercase',
    letterSpacing: '.1em',
  },
  heading: {
    fontFamily: 'heading',
    fontWeight: 'heading',
    lineHeight: 'heading',
  },
  display: {
    variant: 'text.heading',
    fontSize: [6, 7, 8],
    fontWeight: 'display',
  },
}

export const links = {
  nav: {
    fontWeight: 'bold',
    display: 'inline-block',
    color: 'inherit',
    textDecoration: 'none',
    ':hover,:focus,.active': {
      color: 'primary',
    },
  },
}

export const images = {
  avatar: {
    height: '100%',
    width: '100%',
    borderRadius: '50%',
  },
}

export const cards = {
  primary: {
    padding: 2,
    borderRadius: 4,
    boxShadow: 'card',
  },
}

export const layout = {
  html: {
    boxSizing: 'border-box',
    fontSize: 0,
    MsTextSizeAdjust: '100%',
    WebkitTextSizeAdjust: '100%',
  },
  body: {
    margin: 0,
    fontFamily: 'body',
    fontWeight: 'body',
    lineHeight: 'body',
    fontKerning: 'normal',
    wordWrap: 'break-word',
    fontFeatureSettings: '"kern", "liga", "clig", "calt"',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  container: {
    px: 4,
  },
  header: {
    p: 4,
    mb: 4,
  },
  footer: {
    p: 4,
  },
}

export const forms = {
  label: {},
  input: {},
  select: {},
  textarea: {},
  radio: {},
  checkbox: {},
  slider: {},
}

export const badges = {}

export const alerts = {}

export const messages = {}
