/** @jsx jsx */
import { jsx } from 'theme-ui'
import Prism from '@theme-ui/prism'
import PrismCore from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-json'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-typescript'
import 'prismjs/components/prism-js-extras'
import 'prismjs/components/prism-markup'
import 'prismjs/components/prism-jsx'
import 'prismjs/components/prism-tsx'
import 'prismjs/components/prism-lua'

const components = {
  pre: (props) => props.children,
  code: (props) => <Prism {...props} Prism={PrismCore} />,
}

export default components
