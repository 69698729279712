import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'

export const wrapRootElement = (
  { element },
  { identity = {}, indieauth, webmentionIo = {} } = {}
) =>
  React.createElement(
    Fragment,
    null,
    React.createElement(
      Helmet,
      null,
      indieauth &&
        React.createElement('link', {
          rel: 'authorization_endpoint',
          href: 'https://indieauth.com/auth',
        }),
      indieauth &&
        React.createElement('link', {
          rel: 'token_endpoint',
          href: 'https://indieauth.com/token',
        }),
      webmentionIo.webmention &&
        webmentionIo.username &&
        React.createElement('link', {
          rel: 'webmention',
          href: `https://webmention.io/${webmentionIo.username}/webmention`,
        }),
      webmentionIo.pingback &&
        webmentionIo.username &&
        React.createElement('link', {
          rel: 'pingback',
          href: `https://webmention.io/${webmentionIo.username}/xmlrpc`,
        }),
      identity.email &&
        React.createElement('link', {
          href: `mailto:${identity.email}`,
          rel: 'me',
        }),
      identity.github &&
        React.createElement('link', {
          href: `https://github.com/${identity.github}`,
          rel: 'me',
        }),
      identity.twitter &&
        React.createElement('link', {
          href: `https://twitter.com/${identity.twitter}`,
          rel: 'me',
        }),
      identity.pgpkey &&
        React.createElement('link', {
          href: identity.pgpkey,
          rel: 'pgpkey',
        })
    ),
    element
  )
