module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1380,"linkImagesToOriginal":false}},{"resolve":"gatsby-remark-copy-linked-files"},{"resolve":"gatsby-remark-smartypants"}],"remarkPlugins":[null,null],"defaultLayouts":{},"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"muniftanjim.dev","short_name":"muniftanjim.dev","start_url":"/","background_color":"#fff","theme_color":"#000","display":"minimal-ui","icon":"src/images/avatar.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8f6e7c79d8d696115bff4e6a6a543f0e"},
    },{
      plugin: require('../plugins/plugin-indieweb/gatsby-browser.js'),
      options: {"plugins":[],"identity":{"email":"hello@muniftanjim.dev","github":"MunifTanjim","twitter":"MunifTanjim","pgpkey":"https://keybase.io/muniftanjim/pgp_keys.asc"},"indieauth":true,"webmentionIo":{"domain":"muniftanjim.dev","username":"muniftanjim.dev","pingback":true,"webmention":true,"fetchLimit":10000,"token":"BhJ1Cgu1dFPojrzva5LhKQ"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
