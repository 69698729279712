/** @jsx jsx */
import { jsx, ThemeProvider } from 'theme-ui'
import components from './components'
import theme from './theme'

export function wrapRootElement({ element }) {
  return (
    <ThemeProvider theme={theme} components={components}>
      {element}
    </ThemeProvider>
  )
}
