import prismPreset from '@theme-ui/prism/presets/vs-dark'
import {
  alerts,
  badges,
  buttons,
  cards,
  forms,
  grids,
  images,
  layout,
  links,
  messages,
  text,
} from './variants'

const heading = {
  color: 'text',
  fontFamily: 'heading',
  lineHeight: 'heading',
  fontWeight: 'heading',
}

export const theme = {
  breakpoints: ['40rem', '60rem'],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: 'inherit',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  colors: {
    text: '#000',
    background: '#fff',
    primary: '#663399',
    secondary: '#ffcd00',
    muted: '#f6f6f6',
  },
  sizes: {
    container: 920,
  },
  shadows: {
    card: '0 0 4px 1px rgba(0, 0, 0, .125)',
  },
  styles: {
    root: {
      fontFamily: 'body',
      fontSize: 3,
      lineHeight: 'body',
      fontWeight: 'body',
      button: {
        fontFamily: 'body',
        cursor: 'pointer',
      },
    },
    h1: {
      ...heading,
      fontSize: 6,
    },
    h2: {
      ...heading,
      fontSize: 5,
    },
    h3: {
      ...heading,
      fontSize: 4,
    },
    h4: {
      ...heading,
      fontSize: 3,
    },
    h5: {
      ...heading,
      fontSize: 2,
    },
    h6: {
      ...heading,
      fontSize: 1,
    },
    p: {
      color: 'text',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
    },
    a: {
      color: 'primary',
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit',
      },
      px: '1rem',
      py: '0.5rem',
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit',
      ...prismPreset,
    },
    inlineCode: {
      fontFamily: 'monospace',
      fontSize: 'inherit',
      bg: '#f6f6f6',
      border: '1px solid #dedede',
      borderRadius: '0.25em',
      padding: '0em 0.1em',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    img: {
      maxWidth: '100%',
    },
  },

  alerts,
  badges,
  buttons,
  cards,
  forms,
  grids,
  images,
  layout,
  links,
  messages,
  text,
}

export default theme
